































interface IFolder {
  label: string;
  id: string;
  children: IFolder[];
  isOpen: boolean;
}

import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { PropType } from "vue";
import {
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BForm,
  BSidebar,
  VBToggle,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from "bootstrap-vue";


@Component({
  name: "node",
  components: {
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BSidebar,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
  },
})
export default class Node extends Vue {
  @Prop({ type: Array as PropType<IFolder[]> })
  Folders!: IFolder[];
  selected: string = "Reports";
  mounted() {
    console.log(this.Folders);
  }
  @Watch("Folders")
  onPropertyChanged(value: string, oldValue: string) {
    console.log(value);
  }
  openChild(item: IFolder) {
    item.isOpen = !item.isOpen;
  }

  DownloadClick(item: any) {
    const urll = `/ExportChartsApi/DownloadMonthlyReport?folderName=${item.id}`;

    this.$http.get(urll, { responseType: "blob" }).then((response: any) => {
      console.log(response.data);
      // console.log(response.blob())

      const contentDispositionHeader = response.headers["content-disposition"];
      const contentType = response.headers["content-type"];
      const reportName = contentDispositionHeader
        .split(";")[1]
        .trim()
        .split("=")[1]
        .split('"')
        .join("");
      const blob = new Blob([response.data], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = reportName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }
}
