
















import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import dataTable from "../components/DataTable.vue";
import FolderViewer from "../components/FolderViewer.vue";
import {
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BForm,
  BSidebar,
  VBToggle,
  BButton,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from "bootstrap-vue";
class IFolder {
  label: string;
  id: string;
  children: IFolder[];
  isOpen: boolean = false;
}
class TaskStatus {
  isFinish: boolean = true;
  hasError: boolean = false;
  massage: string;
}
@Component({
  components: {
    dataTable,
    FolderViewer,
    BCard,
    BCardText,
  },
})
export default class ReportExplorer extends Vue {
  folders: IFolder[] = new Array();

  taskStatus: TaskStatus = new TaskStatus();
  created() {
    this.enumarateReports();
    this.getStatus();
  }
  getStatus() {
    axios
      .get(`/ExportChartsApi/MonthlyReportStatus`, {
        withCredentials: true,
      })
      .then((response) => {
        this.taskStatus = response.data;
        window.console.log(response.data);
        if (!this.taskStatus.isFinish) {
          setTimeout(() => {
            this.getStatus();
          }, 20000);
        } else {
          this.enumarateReports()
        }
      });
  }
  enumarateReports() {
    axios
      .get(`/ExportChartsApi/EnumerateReports`, {
        withCredentials: true,
      })
      .then((response) => {
        this.folders = this.removeEmtyChilds(response.data);
        window.console.log(response.data);
      });
  }
  removeEmtyChilds(folders: IFolder[]) {
    return folders.map((x) => {
      const folders = new IFolder();
      if (x.children) {
        folders.label = x.label;
        folders.id = x.id;
        folders.children = this.removeEmtyChilds(x.children);
        folders.isOpen = false;
      } else {
        folders.label = x.label;
        folders.id = x.id;
      }
      return folders;
    });
  }
}
