




















interface IFolder {
  label: string;
  id: string;
  children: IFolder[];
  isOpen: boolean;
}

import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { PropType } from "vue";
import {
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BForm,
  BSidebar,
  VBToggle,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from "bootstrap-vue";
import Node from "./Node.vue";

@Component({
  name: "folder-viewer",
  components: {
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BSidebar,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    Node,
  },
})
export default class FolderViewer extends Vue {
  @Prop({ type: Array as PropType<IFolder[]> })
  Folders!: IFolder[];
  selected: string = "Reports";
  mounted() {
    console.log(this.Folders);
  }
  // @Watch('Folders')
  // onPropertyChanged(value: string, oldValue: string) {
  //   console.log(value)
  // }
  openChild(item: IFolder) {
    item.isOpen = !item.isOpen;
    console.log(item);
  }
  DownloadClick(item: any) {
    console.log(item);
    this.$emit("onDownload", item);
  }
}
